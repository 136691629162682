import { useCallback } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useRequestCreationData } from 'store/requestCreation/requestCreation';
import { useChatMessagesData } from 'store/chat/chat';
import { useNavigate } from 'react-router-dom';
import { executeRequestFlow, updateRequest } from 'api/request';
import { sendChatMessage } from 'api/chat';
import { ChatType } from '@ppm/ppm-platform-chat-service';
import { useRequestData } from 'store/request/request';
import { getGrantInfo } from 'common/helpers';
import { statusesOfPurchasePriceValidation } from 'common/consts';
import { getHasGrants, getTransitionKeyByGrant, parseDateForMozaicComponent } from 'common/utils';
import { Grants } from '@ppm/ppm-platform-user-service';
import { useAppTranslations } from 'translations';
import { useDueDateData } from 'store/dictionary/dueDate';
import { useModals } from '@mozaic-ds/react';
import { confirmedModalId } from './consts';
import { schema } from './schema';
import { ConfirmFormFields, FinishedFormFields } from './types';

export const useConfirmForm = () => {
  const t = useAppTranslations();

  const { control, reset } = useForm<ConfirmFormFields>({
    criteriaMode: 'all',
    mode: 'onChange',
    defaultValues: {
      first: false,
      second: false,
      third: false,
      fourth: false,
    },
  });

  const { field: first } = useController({ name: 'first', control });
  const { field: second } = useController({ name: 'second', control });
  const { field: third } = useController({ name: 'third', control });
  const { field: fourth } = useController({ name: 'fourth', control });

  const fields = [first, second, third, fourth];

  const confirmations = new Map<string, string>();
  confirmations.set(first.name, t('confirmProvideData'));
  confirmations.set(second.name, t('confirmPossibilityToDeclinePriceChanging'));
  confirmations.set(third.name, t('confirmProcedureOfAgreeingPrice'));
  confirmations.set(fourth.name, t('confirmProcedureOfPriceActivation'));

  return { fields, reset, confirmations };
};

const useDefaultFormValue = () => {
  const { request } = useRequestCreationData().data;
  const { messages } = useChatMessagesData().chatMessages;

  return {
    comment: messages.length ? messages[0].message : '',
    dueDate: request?.dueDate ? parseDateForMozaicComponent(new Date(request.dueDate)) : '',
  };
};

export const useCreateRequestFinishFrom = () => {
  const navigate = useNavigate();
  const { close } = useModals();

  const { request } = useRequestCreationData().data;
  const { dueDate: date } = useDueDateData().data;
  const { containers } = useRequestData().data;

  const id = request?.id as string;

  const defaultValues = useDefaultFormValue();
  const { control, handleSubmit } = useForm<FinishedFormFields>({
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const { field: dueDate } = useController({ name: 'dueDate', control });
  const { field: comment } = useController({ name: 'comment', control });

  const submitForm = useCallback(
    async (values: FinishedFormFields) => {
      try {
        if (values.dueDate !== defaultValues.dueDate) {
          await updateRequest({
            urlParameters: [id],
            body: { dueDate: values.dueDate },
          });
        }
        if (values.comment !== defaultValues.comment) {
          await sendChatMessage({
            urlParameters: [id, ChatType.External],
            body: { message: values.comment },
          });
        }

        const { transitionStatus } = getTransitionKeyByGrant(
          containers[0].flowRights.transitions,
          Grants[
            getGrantInfo(
              getHasGrants(containers[0].flowRights.grants),
              statusesOfPurchasePriceValidation
            )
          ]
        );

        if (!transitionStatus) return null;

        await executeRequestFlow({
          urlParameters: [id, transitionStatus],
        });
        navigate(`/request/${id}`);

        return null;
      } catch (e) {
        close(confirmedModalId);
        return e;
      }
    },
    [defaultValues.dueDate, defaultValues.comment, containers, id, navigate, close]
  );

  return { dueDate, comment, handleSubmit, submitForm, date };
};
