import React, { useEffect, useState, useCallback } from 'react';
import CbdTable from 'pages/cbd/hok/partials/CbdTable/CbdTable';
import Modal from 'components/Modal/Modal';
import { ICatalogCostTreeItem } from 'common/cbd/types';
import { useAppDispatch } from 'store/hooks';
import {
  useStructureList,
  setSelectedRowsId,
  setIsAdd,
  setEditItems,
} from 'store/cbd/templateStructure/list';
import { TemplateStructureColumn, useColumnsData } from 'store/cbd/templateStructure/columns';
import { useLoadErrorCodes } from 'store/cbd/loadErrorCodes';
import {
  View,
  Flex,
  Text,
  IconButton,
  TextArea,
  Modal as ModalMozaic,
  ModalBody,
  ModalHeader,
  ModalTitle,
  useModals,
  Table,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableContainer,
  TableBody,
  TableCell,
  Select,
  CheckBox,
  TextInput,
} from '@mozaic-ds/react';
import ColumnsPopup from 'pages/cbd/hok/partials/ColumnsPopup/ColumnsPopup';
import {
  PublishEdit24,
  ControlMore24,
  PublishTrashbin24,
  NotificationAvailable24,
  ControlCross24,
} from '@mozaic-ds/icons/react';
import { useAppTranslations } from 'translations';
import classNames from 'classnames';
import {
  IUpdateCatalogCosts,
  createCatalogCosts,
  deleteCatalogCosts,
  updateCatalogCosts,
  createAttributes,
  updateAttributes,
  deleteAttributes,
  ICreateAttribute,
} from 'api/cbd/templates';
import styles from './ParseTable.module.scss';
import { TemplateStructureColumns } from './hooks/consts';
import {
  useGetColumns,
  useColumnsForm,
  sortConfig,
  IMappingModal,
  IAttributesModal,
} from './hooks/columns';
import { useTableData } from './hooks/data';
import FiltersPopup from './components/FiltersPopup';
import AddAttributeButton from './components/AddAttributeButton';
import EditAttributeButton from './components/EditAttributeButton';

const NEW_ATTRIBUTE_ID = '-1';
const attrFormInitialState: ICreateAttribute = {
  id: NEW_ATTRIBUTE_ID,
  name: '',
  mapping: '',
  errRequiredId: null,
  required: false,
};
const attrModalInitialState = {
  attributes: [],
  callBack: () => {},
  parentId: 0,
  parentCode: '',
};

const ParseTable = () => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();
  const { close, open } = useModals();
  const [mappingModal, setMappingModal] = useState<IMappingModal>({
    title: '',
    value: '',
    callBack: () => {},
  });
  const [attributesModal, setAttributesModal] = useState<IAttributesModal>(attrModalInitialState);
  const [selectedAttributes, setSelectedAttributes] = useState<Array<string>>([]);
  const [attributesTableStatus, setAttributesTableStatus] = useState<string>('read');
  const [attributeForm, setAttributeForm] = useState<ICreateAttribute>(attrFormInitialState);

  const { data, selectedRowsId, isAdding, editItems } = useStructureList();
  const {
    paginationProps,
    size,
    updateTableData,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  } = useTableData();

  const { columns } = useGetColumns(setMappingModal, (config) => {
    setSelectedAttributes([]);
    setAttributesTableStatus('read');
    setAttributeForm({ ...attrFormInitialState });
    setAttributesModal(config);
  });
  const { columns: columnsFilter, sortedField } = useColumnsData().data;
  const { form, saveColumnsFilter, resetColumnsFilters } = useColumnsForm();
  const { list: loadErrorCodes } = useLoadErrorCodes();

  const onAddClick = async () => {
    try {
      if (isAdding) {
        await createCatalogCosts({
          parentId: Number(selectedRowsId[0]),
          code: data[0].code,
          name: data[0].name,
          required: data[0].required,
          unique: data[0].unique,
          mappingSum: data[0].mappingSum ? JSON.parse(data[0].mappingSum) : null,
          mappingCur: data[0].mappingCur ? JSON.parse(data[0].mappingCur) : null,
          errRequiredSumId: data[0].errRequiredSumId,
          errRequiredCurId: data[0].errRequiredCurId,
          primeCost: data[0].primeCost,
          purchasePrice: data[0].purchasePrice,
        });
        dispatch(setEditItems([]));
        dispatch(setSelectedRowsId([]));
        updateTableData();
      }
      dispatch(setIsAdd(!isAdding));
    } catch {
      dispatch(setEditItems([]));
      dispatch(setSelectedRowsId([]));
      dispatch(setIsAdd(!isAdding));
      updateTableData();
    }
  };

  const getEditItem = (
    catalogCost: ICatalogCostTreeItem[],
    id: string
  ): ICatalogCostTreeItem | null => {
    return catalogCost.reduce((prev: ICatalogCostTreeItem | null, curr) => {
      if (curr.id.toString() === id) {
        return curr;
      }

      if (prev) {
        return prev;
      }

      return getEditItem(curr.childrens, id);
    }, null);
  };

  const onEditClick = async () => {
    try {
      if (editItems.length) {
        const changedRows = editItems.reduce((prev: IUpdateCatalogCosts[], curr) => {
          const item = getEditItem(data, curr);

          if (item) {
            prev.push({
              id: Number(item.id),
              code: item.code,
              name: item.name,
              required: item.required,
              unique: item.unique,
              mappingSum: item.mappingSum ? JSON.parse(item.mappingSum) : null,
              mappingCur: item.mappingCur ? JSON.parse(item.mappingCur) : null,
              errRequiredSumId: item.errRequiredSumId,
              errRequiredCurId: item.errRequiredCurId,
              primeCost: item.primeCost,
              purchasePrice: item.purchasePrice,
              parentId: item.parentId,
            });
          }

          return prev;
        }, []);

        await updateCatalogCosts(changedRows);

        dispatch(setSelectedRowsId([]));
        dispatch(setEditItems([]));
        updateTableData();
        return;
      }

      dispatch(setEditItems(selectedRowsId));
    } catch (e) {
      dispatch(setEditItems([]));
      dispatch(setSelectedRowsId([]));
      updateTableData();
    }
  };

  const onCancel = () => {
    updateTableData();
    dispatch(setIsAdd(false));
    dispatch(setSelectedRowsId([]));
    dispatch(setEditItems([]));
  };

  const table = {
    sortConfig,
    columns,
    sortedField,
    columnsFilter,
    selectedRowsId,
    setSelectedRowsId: (selected: string[]) => dispatch(setSelectedRowsId(selected)),
  };
  const pagination = {
    paginationProps,
    size,
    onPageChange,
    onNext,
    onPrevious,
    onSizeChange,
    getPaginationInfo,
  };
  const columnsPopup = { columns, columnsFilter, form, saveColumnsFilter, resetColumnsFilters };

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);

  const resetForm = useCallback(() => {
    close('attributesEdit');
    updateTableData();
    setAttributesModal(attrModalInitialState);
    setAttributesTableStatus('read');
    setSelectedAttributes([]);
    setAttributeForm(attrFormInitialState);
  }, [close, updateTableData]);

  const createAttr = useCallback(() => {
    const mapping =
      typeof attributeForm.mapping === 'string'
        ? JSON.parse(attributeForm.mapping)
        : attributeForm.mapping;
    createAttributes({ ...attributeForm, mapping, costId: attributesModal.parentId }).then(
      (res) => {
        resetForm();
        return res;
      }
    );
  }, [attributesModal, attributeForm, resetForm]);

  const updateAttr = useCallback(() => {
    const mapping =
      typeof attributeForm.mapping === 'string'
        ? JSON.parse(attributeForm.mapping)
        : attributeForm.mapping;
    updateAttributes({ ...attributeForm, mapping, costId: attributesModal.parentId }).then(
      (res) => {
        resetForm();
        return res;
      }
    );
  }, [attributesModal, attributeForm, resetForm]);

  const deleteAttr = useCallback(() => {
    deleteAttributes(selectedAttributes.map(Number)).then((res) => {
      resetForm();
      return res;
    });
  }, [selectedAttributes, resetForm]);

  const closeAttrForm = useCallback(() => {
    setAttributesTableStatus('read');
    setSelectedAttributes([]);
  }, [setAttributesTableStatus, setSelectedAttributes]);

  return (
    <View className={classNames(styles.wrapper, 'mu-mb-300')}>
      <Flex alignItems="center" justifyContent="space-between" marginBottom="mu200">
        <Text weight="semi-bold">{translations('CBDDataStructure')}</Text>
        <Flex>
          <IconButton
            className="mu-mr-100"
            onClick={onAddClick}
            size="s"
            theme="primary"
            variant="bordered"
            isDisabled={selectedRowsId.length !== 1}
          >
            {isAdding ? <NotificationAvailable24 /> : <ControlMore24 />}
          </IconButton>
          {isAdding && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            className="mu-mr-100"
            onClick={onEditClick}
            size="s"
            theme="primary"
            variant="bordered"
            isDisabled={selectedRowsId.length !== 1}
          >
            {Boolean(editItems.length) && !isAdding ? (
              <NotificationAvailable24 />
            ) : (
              <PublishEdit24 />
            )}
          </IconButton>
          {Boolean(editItems.length) && !isAdding && (
            <IconButton
              className="mu-mr-100"
              onClick={onCancel}
              theme="danger"
              variant="bordered"
              size="s"
            >
              <ControlCross24 />
            </IconButton>
          )}
          <IconButton
            className="mu-mr-100"
            onClick={() => open('deleteConfirmation')}
            isDisabled={!selectedRowsId.length}
            size="s"
            theme="danger"
            variant="bordered"
          >
            <PublishTrashbin24 />
          </IconButton>
          <FiltersPopup />
          <View marginLeft="mu100" marginRight="mu100">
            <ColumnsPopup<TemplateStructureColumns, TemplateStructureColumn, ICatalogCostTreeItem>
              {...columnsPopup}
            />
          </View>
        </Flex>
      </Flex>
      <CbdTable<TemplateStructureColumns, ICatalogCostTreeItem>
        rows={data}
        isLoading={false}
        {...table}
        pagination={pagination}
      />
      <ModalMozaic id="attributesEdit">
        <ModalHeader>
          <ModalTitle>
            {translations('attributes')}. {translations('catalogCostCode')}:
            {attributesModal.parentCode}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Flex justifyContent="flex-end" marginBottom="mu100">
            <AddAttributeButton
              isAdding={attributesTableStatus === 'add' && selectedAttributes.length === 1}
              isFormOpeningDisabled={
                attributesTableStatus !== 'read' || selectedAttributes.length > 0
              }
              openForm={() => {
                setAttributesTableStatus('add');
                setSelectedAttributes([NEW_ATTRIBUTE_ID]);
                setAttributeForm(attrFormInitialState);
              }}
              closeForm={closeAttrForm}
              createAttribute={createAttr}
            />
            <EditAttributeButton
              isEditing={attributesTableStatus === 'edit' && selectedAttributes.length === 1}
              isFormOpeningDisabled={
                selectedAttributes.length !== 1 || attributesTableStatus !== 'read'
              }
              openForm={() => {
                setAttributesTableStatus('edit');
                const attribute = attributesModal.attributes.find(
                  (item) => item.id === selectedAttributes[0]
                );
                if (attribute) {
                  setAttributeForm({
                    ...attribute,
                    errRequiredId: attribute.errRequired?.id || null,
                  });
                }
              }}
              closeForm={closeAttrForm}
              updateAttribute={updateAttr}
            />
            <IconButton
              isDisabled={!selectedAttributes.length || attributesTableStatus !== 'read'}
              theme="danger"
              variant="bordered"
              size="s"
              onClick={deleteAttr}
            >
              <PublishTrashbin24 />
            </IconButton>
          </Flex>
          <Flex marginBottom="mu100">
            <TableContainer>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>{translations('catalogAttributeName')}</TableHeaderCell>
                    <TableHeaderCell>{translations('catalogAttributeMapping')}</TableHeaderCell>
                    <TableHeaderCell>
                      {translations('catalogAttributeRequiredErrId')}
                    </TableHeaderCell>
                    <TableHeaderCell>{translations('catalogAttributeRequired')}</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(attributesTableStatus === 'add'
                    ? [{ ...attrFormInitialState, id: NEW_ATTRIBUTE_ID }].concat(
                        attributesModal.attributes
                      )
                    : attributesModal.attributes
                  ).map((item: ICreateAttribute) => {
                    return (
                      <TableRow
                        key={item.id}
                        onClick={() => {
                          if (attributesTableStatus === 'read' && item.id) {
                            setSelectedAttributes(
                              selectedAttributes.includes(item.id)
                                ? selectedAttributes.filter((s) => s !== item.id)
                                : selectedAttributes.concat(item.id)
                            );
                          }
                        }}
                        className={classNames({
                          [styles.selected]: item.id && selectedAttributes.includes(item.id),
                        })}
                      >
                        <TableCell>
                          {item.id &&
                          selectedAttributes.includes(item.id) &&
                          attributesTableStatus !== 'read' ? (
                            <TextInput
                              size="s"
                              value={attributeForm.name || undefined}
                              onChange={(e) =>
                                setAttributeForm({ ...attributeForm, name: e.target.value })
                              }
                            />
                          ) : (
                            item.name
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            isDisabled={
                              !(
                                item.id &&
                                selectedAttributes.includes(item.id) &&
                                attributesTableStatus !== 'read'
                              )
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              open('fieldEdit');
                              setMappingModal({
                                title: translations('editingCurrencyMapping'),
                                value:
                                  typeof attributeForm.mapping === 'string'
                                    ? attributeForm.mapping
                                    : JSON.stringify(attributeForm.mapping),
                                callBack: (value) => {
                                  setAttributeForm({ ...attributeForm, mapping: value });
                                },
                              });
                            }}
                            size="s"
                            theme="neutral"
                            variant="bordered"
                          >
                            <PublishEdit24 />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          {item.id &&
                          selectedAttributes.includes(item.id) &&
                          attributesTableStatus !== 'read' ? (
                            <Select
                              placeholder=" "
                              size="s"
                              value={attributeForm.errRequiredId || undefined}
                              onChange={(e) =>
                                setAttributeForm({
                                  ...attributeForm,
                                  errRequiredId: Number(e.target.value),
                                })
                              }
                              options={loadErrorCodes}
                            />
                          ) : (
                            item.errRequired?.id
                          )}
                        </TableCell>
                        <TableCell>
                          <CheckBox
                            isChecked={
                              item.id &&
                              selectedAttributes.includes(item.id) &&
                              attributesTableStatus !== 'read'
                                ? Boolean(attributeForm.required)
                                : Boolean(item.required)
                            }
                            onChange={() => {
                              if (
                                item.id &&
                                selectedAttributes.includes(item.id) &&
                                attributesTableStatus !== 'read'
                              ) {
                                setAttributeForm({
                                  ...attributeForm,
                                  required: !attributeForm.required,
                                });
                              }
                            }}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Flex>
        </ModalBody>
      </ModalMozaic>
      <Modal
        id="fieldEdit"
        text=""
        title={mappingModal.title}
        okBtnText={translations('apply')}
        cancelBtnText={translations('cancel')}
        isLoading={false}
        handleOk={() => {
          close('fieldEdit');
          mappingModal.callBack(mappingModal.value);
        }}
        onClose={() => close('fieldEdit')}
      >
        <TextArea
          value={mappingModal.value}
          onChange={(e) => {
            setMappingModal((state) => ({
              ...state,
              value: e.target.value,
            }));
          }}
        />
      </Modal>
      <Modal
        id="deleteConfirmation"
        text={translations('cbdCostRemovalConfirmation')}
        title={translations('cbdCostRemovalTitle')}
        okBtnText={translations('confirmModalBtn')}
        cancelBtnText={translations('cancel')}
        isLoading={false}
        handleOk={() => {
          close('deleteConfirmation');
          deleteCatalogCosts(selectedRowsId).then(() => updateTableData());
          dispatch(setSelectedRowsId([]));
        }}
        onClose={() => close('deleteConfirmation')}
      />
    </View>
  );
};

export default ParseTable;
