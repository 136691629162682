import React from 'react';
import { useMount } from 'hook/useMount';
import { getReasons, useReasonsData } from 'store/dictionary/reasons';
import { getRequest, useRequestData } from 'store/request/request';
import { getMessages, useChatMessagesData } from 'store/chat/chat';
import { ChatType } from '@ppm/ppm-platform-chat-service';
import { Loader } from '@mozaic-ds/react';
import { FinishRequestCreation } from 'pages/CreatingRequest/Steps/FinishRequestCreation/FinishRequestCreation';
import { useAppDispatch } from 'store/hooks';
import { useRequestCreationData } from 'store/requestCreation/requestCreation';
import { getHasGrants } from 'common/utils';
import { useAppTranslations } from 'translations';

export const FinishRequestCreationContainer = () => {
  const dispatch = useAppDispatch();
  const t = useAppTranslations();

  const { requestGrants, isLoading: isRequestLoading } = useRequestData().data;
  const { request } = useRequestCreationData().data;
  const { reasons } = useReasonsData().data;
  const { isLoading } = useChatMessagesData().chatMessages;

  const { RequestExternalChatRead, RequestInternalChatRead } = getHasGrants(requestGrants);

  useMount(() => {
    if (reasons.length === 0) dispatch(getReasons(t));
    if (request?.id) dispatch(getRequest(request.id));
    if (RequestExternalChatRead || RequestInternalChatRead)
      dispatch(getMessages({ id: request?.id as string, chatType: ChatType.External }));
  });

  if (!request || isLoading || isRequestLoading) return <Loader />;

  return <FinishRequestCreation />;
};
