import React, { FC, useEffect } from 'react';
import {
  Button,
  CheckBox,
  Flex,
  Loader,
  Overlay,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@mozaic-ds/react';
import { useAppTranslations } from 'translations';
import { Controller } from 'react-hook-form';
import { useSupplierBuyerSiteGroupLinks } from 'store/buyerSiteGroup/supplierBuyerSiteGroupLinks';
import { useUserData } from 'store/auth/user';
import { useBuyerSiteGroupHierarchy } from 'store/buyerSiteGroup/buyerSiteGroupHierarchy';
import { getHasGrants } from 'common/utils';
import { SelectItem } from 'common/types';
import { useBSGForm } from './hooks';
import styles from './BuyerSiteGroup.module.scss';

type Props = {
  suppliers: SelectItem[];
};

const BuyerSiteGroupTable: FC<Props> = ({ suppliers }) => {
  const translations = useAppTranslations();
  const { data: headers } = useBuyerSiteGroupHierarchy().data;
  const { data: rows } = useSupplierBuyerSiteGroupLinks().data;
  const { userGrants } = useUserData().user;
  const { AdminSupplierBsgLinkWrite } = getHasGrants(userGrants);

  const { form, handleChange, handleSubmit, fields, replace, watchFields, defaultFormValue } =
    useBSGForm();

  useEffect(() => {
    if (!suppliers.length || !rows.length) replace([]);
  }, [replace, rows, suppliers]);

  return (
    <>
      <Overlay isVisible={form.formState.isSubmitting} />
      <TableContainer withStickyHeader className={styles.horizontalScroll}>
        <Table className={styles.table}>
          <TableHeader>
            <TableRow>
              <TableHeaderCell variant="field" key="bsgLocations">
                {translations('bsgLocations')}
              </TableHeaderCell>
              {headers.map(({ buyerGroupSite }) => (
                <TableHeaderCell key={buyerGroupSite.id}>
                  {buyerGroupSite.buyerGroupSiteType.name} {buyerGroupSite.name}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className={styles.firstColumnSticky}>
            {fields.length > 0 &&
              fields.map((item, index) => {
                return (
                  <TableRow key={item.supplierDepartmentId}>
                    <TableCell key={`row-${item.supplierDepartmentId}-bsgLocations`}>
                      {item.supplierName} {item.supplierDepartmentId}
                    </TableCell>
                    {item.link.map((header, i) => (
                      <TableCell key={`row-${item.supplierDepartmentId}-${header.id}`}>
                        <Controller
                          render={({ field }) => (
                            <CheckBox
                              {...field}
                              isDisabled={!AdminSupplierBsgLinkWrite}
                              onChange={(e) => {
                                field.onChange(e);
                                handleChange(e);
                              }}
                              isChecked={field.value}
                              value={field.name}
                            />
                          )}
                          name={`links.${index}.link.${i}.isChecked`}
                          control={form.control}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Flex justifyContent="flex-end" className="mu-mt-200">
        <Button isDisabled={!defaultFormValue.length || watchFields} onClick={handleSubmit}>
          {form.formState.isSubmitting && <Loader size="s" theme="light" className="mu-mr-050" />}
          {translations('validateModifications')}
        </Button>
        <Button
          className="mu-ml-100"
          variant="bordered"
          theme="neutral"
          onClick={() => form.reset()}
          isDisabled={!defaultFormValue.length || watchFields}
        >
          {translations('resetModifications')}
        </Button>
      </Flex>
    </>
  );
};

export default BuyerSiteGroupTable;
