import { IPageable } from 'common/cbd/types';
import { SelectItem } from 'common/types';
import { useCallback, useMemo, useState } from 'react';
import { useAppTranslations } from 'translations';

export interface IUsePagination {
  size: number;
  paginationProps: IPaginationProps;
  onPageChange: (currentPage: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  onSizeChange: (newSize: number) => void;
  getPaginationInfo: () => string;
}

interface IPaginationProps {
  currentPage: number;
  pagesTotal: number;
  options: SelectItem[];
}

const usePagination = (pagination: IPageable): IUsePagination => {
  const translation = useAppTranslations();
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);

  const paginationProps = useMemo(
    () => ({
      currentPage: page,
      pagesTotal: pagination.total.pages < page ? page : pagination.total.pages,
      options: pagination.total.pages
        ? Array(pagination.total.pages)
            .fill('')
            .map((_, index) => ({
              value: index + 1,
              label: `${translation('page')} ${index + 1} ${translation('of')} ${
                pagination.total.pages
              }`,
            }))
        : [{ value: 0, label: translation('zeroPages') }],
    }),
    [page, pagination.total.pages, translation]
  );

  const getPaginationInfo = useCallback(() => {
    const totalPageElements = size * pagination.page.number;
    const firstPageElement = totalPageElements ? totalPageElements - size + 1 : totalPageElements;
    const isTotalElementsMoreThanPaginationTotal = totalPageElements > pagination.total.elements;
    const lastPageElement = isTotalElementsMoreThanPaginationTotal
      ? pagination.total.elements
      : totalPageElements;

    return `${firstPageElement} - ${lastPageElement} ${translation('of')} ${
      pagination.total.elements
    }`;
  }, [pagination, translation, size]);

  const onSizeChange = useCallback(
    (newSize: number) => {
      const pageForNewSize = (page / newSize) * 10;

      setSize(newSize);
      setPage(Math.ceil(pageForNewSize));
    },
    [page]
  );

  const onPageChange = useCallback((currentPage: number) => {
    setPage(currentPage);
  }, []);

  const onNext = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  const onPrevious = useCallback(() => {
    setPage(page - 1);
  }, [page]);

  return {
    size,
    paginationProps,
    onSizeChange,
    onPageChange,
    onNext,
    onPrevious,
    getPaginationInfo,
  };
};

export default usePagination;
