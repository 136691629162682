import React from 'react';
import { RequestGateway } from '@ppm/ppm-platform-api';
import { useRequestCreationData } from 'store/requestCreation/requestCreation';
import { Loader } from '@mozaic-ds/react';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { useRequestData } from 'store/request/request';
import { SocketContextProvider } from 'components/SocketContainer/SocketContext';
import { SupplierTemplateForm } from './SupplierTemplateForm';
import { CbdForm } from './CbdForm';
import { Steps } from '../../types';

export const FileContainer = () => {
  const { request } = useRequestCreationData().data;
  const { request: data, isLoading } = useRequestData().data;
  const { currentStepId } = useStepperContext<Steps>();

  if (!request || !data || isLoading) return <Loader />;

  return (
    <SocketContextProvider
      id={request.id}
      namespace={RequestGateway.namespace}
      keyParam="requestId"
      path={RequestGateway.path}
    >
      {currentStepId === Steps.template ? (
        <SupplierTemplateForm request={data} />
      ) : (
        <CbdForm id={request.id} />
      )}
    </SocketContextProvider>
  );
};
