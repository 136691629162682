import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initPageable } from 'common/consts';
import { IGetCbdLoadListPayload, IGetCbdLoadListResponse } from 'common/cbd/types';
import { useAppSelector } from 'store/hooks';
import { getCbdLoadList } from 'api/cbd/cbd';
import { setFailure, setLoading } from '../../helpers';
import { RequiredStateFields, RootState } from '../../types';

interface SliceState extends RequiredStateFields, IGetCbdLoadListResponse {}

const initialState = {
  data: [],
  pageable: initPageable,
  isLoading: false,
  isFailure: false,
};

export const getLoadList = createAsyncThunk(
  'cbd/getLoadList',
  async (payload: IGetCbdLoadListPayload, { rejectWithValue }) => {
    try {
      return await getCbdLoadList(payload);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loadListSlice = createSlice({
  initialState,
  name: 'loadList',
  reducers: {
    clearLoadListSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoadList.pending, (state: SliceState) => {
        setLoading(state);
      })
      .addCase(
        getLoadList.fulfilled,
        (state: SliceState, action: PayloadAction<IGetCbdLoadListResponse>) => {
          state.data = action.payload.data;
          state.pageable = action.payload.pageable;
          state.isLoading = false;
          state.isFailure = false;
        }
      )
      .addCase(getLoadList.rejected, (state: SliceState) => {
        setFailure(state);
      });
  },
});

export const { clearLoadListSlice } = loadListSlice.actions;

export const useLoadList = (): SliceState => {
  return useAppSelector((state: RootState) => state.cbd.uploads.list);
};
