import React from 'react';
import { Flex } from '@mozaic-ds/react';
import { useAppDispatch } from 'store/hooks';
import { getCurrentPricelistData, useCurrentPriceListData } from 'store/priceList/currentPriceList';
import { useMount } from 'hook/useMount';
import { useParams } from 'react-router-dom';
import { getRequest, resetStore } from 'store/request/request';
import { isEmpty } from 'lodash';
import { getStartDateValidation } from 'store/priceList/validation';
import { Settings } from './partials/PriceListSettings/Settings';
import { Structure } from './partials/Structure';
import { Files } from './partials/Files';
import { PriceListTableContainer } from './partials/Items/PriceListTableContainer';

const PriceList = () => {
  const dispatch = useAppDispatch();
  const { requestId, priceListId } = useParams();
  const { priceList } = useCurrentPriceListData().data;

  useMount(() => {
    if (requestId) dispatch(getRequest(requestId));
    if (priceListId && requestId) {
      dispatch(getStartDateValidation({ id: requestId, query: { pricelistId: priceListId } }));
      dispatch(getCurrentPricelistData({ requestId, priceListId }));
    }
    return () => dispatch(resetStore());
  });

  return (
    <Flex direction="column" marginTop="mu250">
      <Flex justifyContent="space-between">
        <Structure />
        {!isEmpty(priceList) && <Settings />}
      </Flex>
      <Files />
      <PriceListTableContainer />
    </Flex>
  );
};

export default PriceList;
