import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, useModals } from '@mozaic-ds/react';
import { Grants } from '@ppm/ppm-platform-user-service';
import { useAppTranslations } from 'translations';
import { isEmpty } from 'lodash';
import { useGetGrants } from 'pages/Request/hooks';
import { useRequestData } from 'store/request/request';
import { getTransitionKeyByGrant } from 'common/utils';
import Modal from 'components/Modal/Modal';
import {
  statusesOfExplanation,
  statusesOfPurchasePriceValidation,
  statusesOfRejection,
  statusesOfValidation,
} from 'common/consts';
import { getGrantInfo, getGrantsInfo } from 'common/helpers';
import { calculatePermissions } from 'pages/Request/helpers';
import { FunctionChangeStatus } from 'pages/Request/partials/ActionButtons/types';
import { TooltipButtonWrapper } from './TooltipButtonWrapper';
import { useSetRequestToDifferentStatus } from '../hooks';
import { applyModal, purchasePriceNotificationModal, validationButtonState } from '../const';

type ActionButtonsContainersProp = {
  id: string;
};

export const ActionButtonsContainers: FC<ActionButtonsContainersProp> = ({ id }) => {
  const translations = useAppTranslations();
  const { open, close } = useModals();

  const [grantValue, setGrantValue] = useState<Grants | undefined>(undefined);
  const [buttonName, setButtonName] = useState<string>('');

  const { selectedContainers } = useRequestData().data;
  const { runSubRequestFlow } = useSetRequestToDifferentStatus(id);

  const { hasSubRequestGrant } = useGetGrants();
  const {
    RequestTransitionDraftToOnPurchasePriceValidation,
    RequestTransitionOnSupplierValidationToRequiresDetailingFromLMUser,
    RequestTransitionOnPurchasePriceValidationToRequiresDetailingFromSupplier,
    RequestTransitionOnRecommendedRetailPriceValidationToOnPurchasePriceValidation,
    RequestTransitionPricelistFormationToOnPurchasePriceValidation,
    RequestTransitionOnValidationWithDepartmentDirectorToOnValidationWithPurchasingDirector,
    RequestTransitionOnValidationWithPurchasingDirectorToOnRecommendedRetailPriceValidation,
    RequestTransitionOnPurchasePriceValidationToRejected,

    RequestTransitionOnLmValidationToRequestRejected,
    RequestTransitionProposedToRequestRejected,
    RequestTransitionRequestValidatedToPricesRejected,
    RequestTransitionPricelistFormationToRejected,
  } = hasSubRequestGrant;

  const {
    transitionRejectionState,
    transitionValidationState,
    transitionExplanationState,
    transitionPurchasePriceState,
  } = useMemo(() => {
    const subRequestTransitions = calculatePermissions(selectedContainers, 'transitions');
    const rejectionGrant = getGrantInfo(hasSubRequestGrant, statusesOfRejection);
    const validationGrant = getGrantsInfo(hasSubRequestGrant, statusesOfValidation);
    const explanationGrant = getGrantInfo(hasSubRequestGrant, statusesOfExplanation);
    const purchasePriceGrant = getGrantInfo(hasSubRequestGrant, statusesOfPurchasePriceValidation);

    const { transitionState: transitionRejection } = getTransitionKeyByGrant(
      subRequestTransitions,
      Grants[rejectionGrant]
    );
    const transitionsValidation = validationGrant.map((grant) =>
      getTransitionKeyByGrant(subRequestTransitions, Grants[grant])
    );
    const { transitionState: transitionExplanation } = getTransitionKeyByGrant(
      subRequestTransitions,
      Grants[explanationGrant]
    );
    const { transitionState: transitionPurchasePrice } = getTransitionKeyByGrant(
      subRequestTransitions,
      Grants[purchasePriceGrant]
    );
    return {
      transitionRejectionState: transitionRejection,
      transitionValidationState: transitionsValidation,
      transitionExplanationState: transitionExplanation,
      transitionPurchasePriceState: transitionPurchasePrice,
    };
  }, [selectedContainers, hasSubRequestGrant]);

  const openPopup = useCallback<FunctionChangeStatus>(
    ({ grantKey, gtmInfo, statuses, popupId }): void => {
      setButtonName(gtmInfo?.buttonLabel || '');
      setGrantValue(() => Grants[grantKey || getGrantInfo(hasSubRequestGrant, statuses)]);
      open(popupId);
    },
    [hasSubRequestGrant, open]
  );

  if (isEmpty(hasSubRequestGrant)) return null;

  return (
    <>
      {RequestTransitionDraftToOnPurchasePriceValidation && (
        <TooltipButtonWrapper
          isValid={transitionPurchasePriceState?.isValid}
          message={transitionPurchasePriceState?.isValid ? '' : transitionPurchasePriceState?.error}
        >
          <Button
            size="m"
            variant="solid"
            width="fit"
            className="mu-mr-100"
            onClick={() =>
              openPopup({
                grantKey: 'RequestTransitionDraftToOnPurchasePriceValidation',
                popupId: purchasePriceNotificationModal,
                gtmInfo: { buttonLabel: 'approvePurchasePrice' },
                statuses: statusesOfPurchasePriceValidation,
              })
            }
            isDisabled={!transitionPurchasePriceState?.isValid}
          >
            {translations('approvePurchasePrice')}
          </Button>
        </TooltipButtonWrapper>
      )}

      {(RequestTransitionOnSupplierValidationToRequiresDetailingFromLMUser ||
        RequestTransitionOnPurchasePriceValidationToRequiresDetailingFromSupplier ||
        RequestTransitionOnRecommendedRetailPriceValidationToOnPurchasePriceValidation ||
        RequestTransitionPricelistFormationToOnPurchasePriceValidation ||
        RequestTransitionOnValidationWithDepartmentDirectorToOnValidationWithPurchasingDirector ||
        RequestTransitionOnValidationWithPurchasingDirectorToOnRecommendedRetailPriceValidation) && (
        <TooltipButtonWrapper
          isValid={transitionExplanationState?.isValid}
          message={transitionExplanationState?.isValid ? '' : transitionExplanationState?.error}
        >
          <Button
            size="m"
            variant="solid"
            width="fit"
            className="mu-mr-100"
            onClick={() =>
              openPopup({
                popupId: applyModal,
                gtmInfo: { buttonLabel: 'needExplanation' },
                statuses: statusesOfExplanation,
              })
            }
            isDisabled={!transitionExplanationState?.isValid}
          >
            {translations('needExplanation')}
          </Button>
        </TooltipButtonWrapper>
      )}

      {transitionValidationState.map(({ transitionState }) => {
        if (!transitionState?.grant) return null;
        if (!validationButtonState[transitionState.grant]) return null;

        const { grantKey, translationMessage } = validationButtonState[transitionState.grant];

        return (
          <TooltipButtonWrapper
            isValid={transitionState?.isValid}
            message={transitionState?.isValid ? '' : transitionState?.error}
          >
            <Button
              size="m"
              variant="solid"
              width="fit"
              className="mu-mr-100"
              key={transitionState.grant}
              onClick={() =>
                openPopup({
                  grantKey,
                  popupId: applyModal,
                  gtmInfo: {
                    buttonLabel: translationMessage,
                  },
                  statuses: statusesOfValidation,
                })
              }
              isDisabled={!transitionState?.isValid}
            >
              {translations(translationMessage)}
            </Button>
          </TooltipButtonWrapper>
        );
      })}

      {(RequestTransitionOnLmValidationToRequestRejected ||
        RequestTransitionProposedToRequestRejected ||
        RequestTransitionOnPurchasePriceValidationToRejected ||
        RequestTransitionRequestValidatedToPricesRejected ||
        RequestTransitionPricelistFormationToRejected) && (
        <TooltipButtonWrapper
          isValid={transitionRejectionState?.isValid}
          message={transitionRejectionState?.isValid ? '' : transitionRejectionState?.error}
        >
          <Button
            size="m"
            theme="danger"
            variant="bordered"
            width="fit"
            onClick={() =>
              openPopup({
                popupId: applyModal,
                gtmInfo: { buttonLabel: 'reject' },
                statuses: statusesOfRejection,
              })
            }
            isDisabled={!transitionRejectionState?.isValid}
          >
            {translations('reject')}
          </Button>
        </TooltipButtonWrapper>
      )}
      <Modal
        id={applyModal}
        title={translations('securityStep')}
        heading={translations('changingStatus')}
        okBtnText={translations('change')}
        cancelBtnText={translations('cancel')}
        text={translations('areYouSure')}
        handleOk={async () => {
          await runSubRequestFlow({ buttonName, grant: grantValue, popupId: applyModal });
        }}
        onClose={() => close(applyModal)}
      />
      <Modal
        id={purchasePriceNotificationModal}
        title={translations('securityStep')}
        okBtnText={translations('change')}
        cancelBtnText={translations('cancel')}
        text={translations('createRequestPopupText')}
        handleOk={async () => {
          await runSubRequestFlow({
            buttonName,
            grant: grantValue,
            popupId: purchasePriceNotificationModal,
          });
        }}
        onClose={() => close(purchasePriceNotificationModal)}
      />
    </>
  );
};
