import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { useAppTranslations } from 'translations';
import { RatingStarsInput, Heading, Flex, Text, TRatingStarsScore } from '@mozaic-ds/react';
import { useModalRateData, setThanYouPageState } from 'store/modalRate/modalRate';
import { ModalRateForm } from 'components/ModalRate/ModalRateForm';
import { useAppDispatch } from 'store/hooks';
import { modalRatePopupId } from './consts';

export const ModalRate = ({ id }: { id: number }) => {
  const translations = useAppTranslations();
  const dispatch = useAppDispatch();

  const { modalData, isShownThankYouPage } = useModalRateData().data;
  const [score, setScore] = useState<TRatingStarsScore>(0);

  return (
    <Modal
      id={modalRatePopupId}
      title={translations('feedbackTitle')}
      onClose={() => {
        setScore(0);
        dispatch(setThanYouPageState(false));
      }}
    >
      <Heading size="s" weight="semi-bold" textAlign="center" className="mu-mr-100 mu-ml-100">
        {isShownThankYouPage ? (
          translations('feedbackThanksHeading')
        ) : (
          <>
            {score === 0 && translations('feedbackHeading')}
            {score >= 80 && translations('feedbackThanksHeading')}
            {score < 80 && score > 0 && translations('feedbackDisappointmentHeading')}
          </>
        )}
      </Heading>
      {score === 0 && (
        <>
          <Flex justifyContent="center">
            <RatingStarsInput score={score} size="xl" onChange={(e) => setScore(e)} />
          </Flex>
          <Flex justifyContent="space-evenly">
            <Text size="s" theme="light">
              {translations('feedbackHighRating')}
            </Text>
            <Text size="s" theme="light">
              {translations('feedbackLowRating')}
            </Text>
          </Flex>
        </>
      )}

      {!isShownThankYouPage && score < 80 && score > 0 && modalData && modalData.answers && (
        <ModalRateForm answers={modalData.answers} score={score} id={id} />
      )}
    </Modal>
  );
};
