import React from 'react';
import { Notification, View } from '@mozaic-ds/react';
import { Stepper } from 'components/Stepper/Stepper';
import { useStepperContext } from 'components/Stepper/StepperContext';
import { Steps, StepsKey } from 'pages/CreatingRequest/types';
import { isSupplierEnvironment } from 'common/consts';
import { useAppTranslations } from 'translations';
import { useRequestData } from 'store/request/request';
import { createMessageText } from 'pages/CreatingRequest/helpers';
import styles from './CreatingRequest.module.scss';
import { stepsComponent } from './Steps/const';

export const CreatingRequest = () => {
  const translations = useAppTranslations();
  const { request } = useRequestData().data;
  const { currentStepId } = useStepperContext<StepsKey>();

  const Component = stepsComponent[currentStepId];

  return (
    <View marginTop="mu250">
      {isSupplierEnvironment && <Stepper<keyof typeof Steps> />}
      {currentStepId !== Steps.start && request?.createDate && (
        <Notification
          theme="info"
          className="mu-mt-150"
          message={createMessageText({
            date: request.createDate,
            message: translations('requestShouldCreateAgainIfArticlesWasChangedNotification'),
            indexesOfInsertions: [0, 1],
            parseOptions: { separator: /[.,]/g },
          })}
        />
      )}
      <View
        className={styles.form}
        shadow="s"
        radius="l"
        padding="mu250"
        as="form"
        marginTop="mu150"
      >
        <Component />
      </View>
    </View>
  );
};
